.ag-theme-alpine {
    --ag-borders: none;
}

.ag-root ::-webkit-scrollbar {
    width: 12px;
}

.ag-root ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
    border-radius: 0;
}

.ag-root ::-webkit-scrollbar-thumb {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.5);
}

.ag-header-cell.text-center .ag-header-cell-label {
    justify-content: center;
}

.ag-standard-button, .ag-filter-apply-panel-button, .ag-input-field-input {
    color: black !important;
    border-color: black !important;
    border-radius: 0 !important;
}

.ag-standard-button:hover, .ag-list-item.ag-active-item {
    background-color: rgba(47, 46, 47, 0.04) !important;
}

.ag-picker-field-wrapper:focus, .ag-text-field-input-wrapper:focus, .ag-input-field-input:focus {
    box-shadow: 0 0 black !important;
}

.ag-picker-field-wrapper {
    border-radius: 0 !important;
}

.ag-wrapper {
    box-shadow: 0 0 black !important;
}

.ag-icon-menu:hover {
    cursor: pointer;
}
